import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useInView } from 'react-intersection-observer';
import Image from 'src/components/atoms/image';
import NextLink from 'src/components/atoms/link';
import config from 'src/config';
import { BASE_URL, ROUTES } from 'src/helpers/constants';
import { useAppData } from 'src/helpers/use';

import FooterStyle from './style';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';

const Footer = ({ hideDownloadApp }: { hideDownloadApp?: boolean }) => {
  const { t } = useTranslation('common');
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1, rootMargin: '300px' });
  const { appData } = useAppData();
  const phoneNumberAndOfficeHours = appData?.footer?.phone_number_and_office_hours;

  const FACEBOOK_URL = appData?.footer?.facebookUrl;
  const LINE_URL = appData?.footer?.lineUrl;
  const GOOGLE_PLAY_URL = appData?.footer?.googleStoreUrl;
  const APP_STORE_URL = appData?.footer?.appStoreUrl;

  const AboutUs = () => (
    <Box className="footer-section">
      <Typography variant="h3" sx={{ mb: 1, width: '100%' }}>
        {t('home.footer.company_us')}
      </Typography>
      <a href="/about">{t('home.footer.about_us')}</a>
      <a href="/teach">{t('home.footer.teach_us')}</a>
      <a href="/career">{t('home.footer.career')}</a>
      <a href="https://investor.skilllane.com/th/home" target="_blank">
        {t('home.footer.investor_relation_link_text')}
      </a>
    </Box>
  );
  const ContactUs = () => (
    <Box className="footer-section">
      <Typography variant="h3" sx={{ mb: 1, width: '100%' }}>
        {t('home.footer.contact_us')}
      </Typography>
      <a href="/faq" className="icon-text">
        <FontAwesomeIcon style={{ fontSize: '18px' }} icon="fa-regular fa-circle-question" /> {t('home.footer.faq')}
      </a>
      <a href={`tel:${phoneNumberAndOfficeHours?.phone}`} target="_blank" className="icon-text" rel="noreferrer">
        <FontAwesomeIcon style={{ fontSize: '18px' }} icon="fa-regular fa-phone" /> {phoneNumberAndOfficeHours?.phone} (
        {phoneNumberAndOfficeHours?.phone_hours})
      </a>
      <Box my={1}>
        <Divider />
      </Box>
      <a href={FACEBOOK_URL} target="_blank" className="icon-text" rel="noreferrer">
        <Box className="facebook-icon">
          <Image className="facebook-icon" src="/static/icons/footer/facebook-contact.png" alt="facebook-contact" />
        </Box>
        SkillLane
      </a>
      <a href={LINE_URL} target="_blank" className="icon-text" rel="noreferrer">
        <Image style={{ width: 18, height: 18 }} alt="line-icon" src="/static/icons/footer/line-icon.png" />
        @SkillLane
      </a>
      <a href="mailto:info@SkillLane.com" target="_blank" className="icon-text" rel="noreferrer">
        <FontAwesomeIcon style={{ fontSize: '18px' }} icon="fa-regular fa-envelope" /> info@SkillLane.com
      </a>
      <span className="social-hours">({phoneNumberAndOfficeHours?.social_hours})</span>
      <Box mt={1}>
        <Divider />
      </Box>
      {/* <Box mt={2}>
        <iframe
          title="facebook share"
          src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FLearnSkillLane&width=450&layout=standard&action=like&size=small&share=true&height=35&appId&locale=en_US"
          width="100%"
          height="35"
          style={{ border: 'none', overflow: 'hidden' }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      </Box> */}
    </Box>
  );

  const CoursesOnline = () => (
    <Box className="footer-section">
      <Typography variant="h3" sx={{ mb: 1, width: '100%' }}>
        {t('home.footer.courses_online')}
      </Typography>
      <NextLink href={ROUTES.ALL_COURSE}>{t('home.footer.all_courses')}</NextLink>
      <a href="/academic/tuxsa">{t('home.footer.tuxsa')}</a>
      <NextLink href={ROUTES.BUSINESS}>{t('home.footer.business')}</NextLink>
      <a href={`${BASE_URL.CERTIFICATE_URL}/check`}>{t('home.footer.check_cert')}</a>
    </Box>
  );

  const DownloadAppDesktop = () => (
    <div className="app-button-desktop">
      <Box className="title-app-download">{t('home.footer.download_app')}</Box>
      <Box marginRight="8px" component="a" href={GOOGLE_PLAY_URL} target="_blank">
        <Image className="icon-google-play" src="/static/icons/footer/google-play.svg" alt="play-store" />
      </Box>
      <Box component="a" href={APP_STORE_URL} target="_blank">
        <Image className="icon-app-store" src="/static/icons/footer/app-store.svg" alt="app-store" />
      </Box>
    </div>
  );

  const DownloadAppMobile = () => (
    <div className="app-button-mobile">
      <Box className="title-app-download">{t('home.footer.download_app')}</Box>
      <Box marginRight="8px" onClick={() => window.open(GOOGLE_PLAY_URL, '_blank')}>
        <Image className="icon-google-play" src="/static/icons/footer/google-play.svg" alt="play-store" />
      </Box>
      <Box onClick={() => window.open(APP_STORE_URL, '_blank')}>
        <Image className="icon-app-store" src="/static/icons/footer/app-store.svg" alt="app-store" />
      </Box>
    </div>
  );

  const Policy = () => (
    <Box className="footer-bottom">
      <Box className="copyright">
        <Typography>SkillLane © {new Date().getFullYear()} SkillLane.com All rights reserved. </Typography>
        <Typography
          href={`${BASE_URL.IDENTITY_URL}/PrivacyPolicy`}
          target="_blank"
          className="policy"
          component="a"
          fontFamily="Lanna"
        >
          {t('home.footer.policy')}
        </Typography>
        <FontAwesomeIcon width="3px" className="user-agreement" icon="fa-solid fa-circle" style={{ fontSize: '3px' }} />
        <Typography
          href="/user_agreement"
          target="_blank"
          component="a"
          className="user-agreement link"
          fontFamily="Lanna"
        >
          {t('home.footer.user_agreement')}
        </Typography>
        <FontAwesomeIcon width="3px" className="user-agreement" icon="fa-solid fa-circle" style={{ fontSize: '3px' }} />
        <Typography
          href="/privacy-notice"
          target="_blank"
          component="a"
          className="user-agreement link"
          fontFamily="Lanna"
        >
          {t('home.footer.privacy_notice')}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <FooterStyle ref={ref}>
      {inView && (
        <>
          <Divider />
          <Box>
            <Grid container spacing={2} className="footer-container">
              <Grid item xs={12} sm={5} lg={4} xl={4} sx={{ mt: { md: '4px' } }}>
                <Box className="footer-section first">
                  <Box className="overview">
                    <Image
                      className="logo-image"
                      alt="SkillLane"
                      src={`${config.IMAGE_CDN_HOST}/assets/logo.png`}
                      errorImage={`${config.IMAGE_CDN_HOST}/assets/logo.png`}
                    />
                    <div>{t('home.footer.overview_1')}</div>
                    <div>{t('home.footer.overview_2')}</div>
                  </Box>
                  {!hideDownloadApp && <DownloadAppDesktop />}
                </Box>
              </Grid>
              <Grid container className="footer-list" item xs={12} sm={7} lg={8} xl={8}>
                <Grid item xs={12} sm={6} lg={3} xl={3}>
                  <CoursesOnline />
                </Grid>
                <Grid item xs={12} sm={6} lg={3} xl={3}>
                  <AboutUs />
                </Grid>
                <Grid className="contact-us" item xs={12} sm={12} lg={6} xl={6}>
                  <ContactUs />
                </Grid>
                <Grid>{!hideDownloadApp && <DownloadAppMobile />}</Grid>
              </Grid>
            </Grid>
          </Box>
          <Policy />
        </>
      )}
    </FooterStyle>
  );
};

export default Footer;
